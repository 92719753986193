.thumbs-wrapper {
    display: none;
}

.report-container {
    height: 100%;
    width: 100%;
}

.report-container iframe {
    width: 100%;
    height: 100%;
}

html,
body,
#root {
    height: 100%;
}

.min-width-80 {
    min-width: 80px;
}

.min-width-150 {
    min-width: 150px;
}

.greyOpaqueBackground {
    background-color: rgba(0, 0, 0, 1);
    color: white;
}

.bg-grey {
    background: #A9A9A9
}

.salesRankingTableWidth {
    width: 600px;
}

.campaignSidebarAward {
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 40%; /* Adjust this value to move the positioned div up and down */
    background: rgba(0, 0, 0, 0.8);
    font-family: Arial, sans-serif;
    color: #fff;
    width: 60%; /* Set the width of the positioned div */
}

.underline-offset {
    text-underline-offset: 8px;
}

.rbc-event {
    background-image: linear-gradient(
        to right,
        rgba(197 144 115),
        rgba(255 253 225),
        rgba(218 147 88),
        rgba(255 252 213),
        rgba(231 184 134)
    );
    color: #362f78;
    border: 1px solid black;
    font-size: 0.875rem;
    margin: 0.1rem;
    text-transform: capitalize;
}

.rbc-header {
    background-image: linear-gradient(to right, #f1cd5d, #fae371, #e8b94f);
    color: black;
    text-align: center;
    padding: 10px 0;
    border-left: 1px solid black !important;
    border-bottom: 1px solid black !important;
}

.rbc-toolbar {
    font-weight: 600;
    background-color: black;
    color: #eaf3f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin-bottom: 0px;
    font-size: 20px;
}

.rbc-off-range-bg {
    background-color: rgba(53, 70, 80, 0.2);
}

.rbc-btn-group {
    background: white;
    font-size: 1rem;
}

.rbc-active {
    background-image: linear-gradient(
        to right,
        #bf953f,
        #b38728,
        #aa771c
    ) !important;
    color: white !important;
}

.banner-image {
    width: initial !important;
    height: 90vh !important;
}

.small-banner-image {
    width: initial !important;
    height: 40vh !important;
}

.default-image {
    width: initial !important;
    height: initial !important;
}

.carousel.carousel-slider {
    overflow: inherit;
}

.carousel .control-next.control-arrow,
.carousel .control-next.control-arrow:hover {
    background-color: transparent;
    right: 0.4rem;
}

.carousel .control-prev.control-arrow,
.carousel .control-prev.control-arrow:hover {
    background-color: transparent;
    left: 0.4rem;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    opacity: 1;
}

.carousel .control-next.control-arrow:before {
    content: "";
    border: solid rgb(226, 232, 240);
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 12px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
    content: "";
    border: solid rgb(226, 232, 240);
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 12px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.control-dots {
    width: 50% !important;
}

.carousel > button {
    height: 100vh !important;
}

.custom-min-height {
    min-height: 40px;
}

.slider-background {
    background: url(./assets/images/sliderbackground.jpg);
    background-position: center;
    background-size: cover;
}

.mvp-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.omc-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.emc-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.pmc-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.smc-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.agentpersonal-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.leaderpersonal-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.primepersonal-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.trip-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.car-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.toprookie-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.bestrookie-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.leadergroup-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.personalgroup-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.tnc-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.bg-gradient {
    background: linear-gradient(to right, #400000, #ff8c00, #400000); /* Black to dark orange */
}

.bg-group-gradient {
    background: linear-gradient(to right, #000040, #3b82f6, #000040); /* Example colors */
}

.title {
    margin: 0;
}

.subtitle {
    max-width: 576px;
    margin: 36px auto;
    letter-spacing: 0.1em;
    line-height: 1.3em;
}

.countdown-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 -8px 0 -8px;
    background: black;
    height: 20vh;
    align-items: center;
}

.time-section {
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: white !important;
}

.time {
    margin: 0;
    font-size: 60px;
    font-weight: 800; 
}

.small {
    font-size: 18px !important;
}

.max-h-small {
    max-height: 30vh !important;
}

.max-h-base {
    height: 62vh !important;
}

.h-35vh {
    height: 40vh!important;
}

.w-10vw{
    width:10vw!important;
}

.w-20vw{
    width:20vw!important;
}

.h-75vh {
    height: 75vh!important;
}

.w-50vw {
    width: 30vw!important;
}

.h-45vh {
    height: 48vh!important;
}

.min-h-45vh {
    min-height: 48vh!important;
}

.min-h-75vh {
    min-height: 75vh!important;
}

.h-80vh {
    height: 80vh!important;
}

.status {
    height: 4vh;
    width: 5vw
}

.widerstatus {
    height: 4vh;
    width: 5vw
}

.tnc-background {
    background: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_4pSPeG_vc.webp");
    background-position: center;
    background-size: cover;
}

.total-background {
    background: url(./assets/images/tncbg.png);
    background-position: center;
    background-size: cover;
}


.greyOpaqueBackground {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
}

.opaqueBackground {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}

  .bg-pmc-border-image {
    background-image: url("https://ik.imagekit.io/dnddecpho/dndream/D&D_SystemImages_ProfileRing4_Tnad3i7uU.png?updatedAt=1719489247011"); /* Replace with your border image */
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 180px; /* Adjust width as needed */
    height: 180px; /* Adjust height as needed */
    overflow: hidden;
  }

  .bg-emc-border-image {
    background-image: url("https://ik.imagekit.io/dnddecpho/dndream/D&D_SystemImages_ProfileRing3_6_z7LMSTs.png?updatedAt=1719489246806"); /* Replace with your border image */
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 180px; /* Adjust width as needed */
    height: 180px; /* Adjust height as needed */
    overflow: hidden;
  }

  .bg-omc-border-image {
    background-image: url("https://ik.imagekit.io/dnddecpho/dndream/D&D_SystemImages_ProfileRing2_PZLa--HGb.png?updatedAt=1719489247158"); /* Replace with your border image */
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 180px; /* Adjust width as needed */
    height: 180px; /* Adjust height as needed */
    overflow: hidden;
  }

  .bg-smc-border-image {
    background-image: url("https://ik.imagekit.io/dnddecpho/dndream/D&D_SystemImages_ProfileRing5_Es_mDDrAG.png?updatedAt=1719489246979"); 
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 180px; /* Adjust width as needed */
    height: 180px; /* Adjust height as needed */
    overflow: hidden;
  }

  .bg-border-image {
    background-image: url("https://venti-fans.com/wp-content/uploads/2025/03/DD_SystemImages_ProfileRing_CyEedB_f5.webp"); 
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 180px; /* Adjust width as needed */
    height: 180px; /* Adjust height as needed */
    overflow: hidden;
  }

  .bg-orange-special {
    background: #FFA500;
  }
  
  /* Hover effect */
  .hover\:bg-orange-special:hover {
    background: #FFA500;
  }

  .bordersales {
    border-color: #eeeeee;
  }

  .borderorange {
    border-color: #FFA500;
  }

  .fontorange {
    color: #FFA500;
  }
  .hover\:fontorange:hover {
    color: #FFA500;
  }

  .borderconverted {
    border-color: #FFE565;
  }

  .bordercancelled {
    border-color: #C24641;
  }

  .borderrejected {
    border-color: #E5A3FF;
  }

  .borderpending {
    border-color: #C3FD88;
  }


  .main-content-image {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2); /* Scale the image */
    transform-origin: top center; /* Zoom towards the top */
    max-width: 80%; /* Adjust to control the size of the inner image */
    height: 110px; /* Adjust to control the size of the inner image */
    transition: transform 0.3s ease; /* Optional: Add a smooth transition effect */
}

.borderyellow {
    border-width: 8px;
    border-style: solid;
    border-image-source: linear-gradient(to right, #FFD700, #FFD966, #FFE58C, #FFECB3, #FFF9E0, #FFECB3, #FFE58C, #FFD966, #FFD700);
    border-image-slice: 1;
}

.borderblue {
    border-width: 8px;
    border-style: solid;
    border-image-source: linear-gradient(to right, #8ED1FC, #A7D8FF, #BFE0FF, #D8E7FF, #F0F0FF, #D8E7FF, #BFE0FF, #A7D8FF, #8ED1FC);
    border-image-slice: 1;
    
    
}

.bordersilver {
    border-width: 8px;
    border-style: solid;
    border-image-source: linear-gradient(to right, #C0C0C0, #C7C7C7, #D0D0D0, #D9D9D9, #E3E3E3, #D9D9D9, #D0D0D0, #C7C7C7, #C0C0C0);
    border-image-slice: 1;
    
    
}

body {
    margin: 0px!important;
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 1;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}



@media (max-width: 770px) {
    .control-dots {
        width: 100% !important;
    }
}

@media (max-width: 480px) {
    .rbc-btn-group {
        display: none;
    }

    .control-dots {
        width: 100% !important;
    }

    .banner-image {
        height: calc(50vh - 48px) !important;
    }

    .time {
        margin: 0;
        font-size: 40px !important;
        font-weight: 800 !important;
    }

    .countdown-wrapper {
        padding: 2vh;
    }

    .status {
        height: 4vh;
        width: 25vw
    }

    .widerstatus {
        height: 6vh;
        width: 30vw
    }

    .h-img {
        height: 200px!important;
    }
}
